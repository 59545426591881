import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import Image from 'next/image'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import Button from 'src/common/components/button/Button'
import Video from 'src/common/components/video/Video'
import { PHOTO_FRAME_BASE64_STRING } from 'src/common/constants'
import { rem } from 'src/common/utils/css'
import sanitize from 'src/common/utils/js/sanitize'
import FullMedia from 'src/components/text-and-media/FullMedia'
import {
  BACKGROUND_COLORS,
  LAYOUT_TYPES,
  MASK_IMAGE_MAP,
  MEDIA_FRAME_TYPES,
  MEDIA_TYPES,
  PATTERN_TYPES,
  VARIANT_TYPES,
  VERSION_TYPES,
  getGridColsWidth,
} from 'src/components/text-and-media/helpers'
import TextListAndMedia from 'src/components/text-and-media/text-list-media/TextListAndMedia'
import theme from 'src/styles/theme'
import { useLinkClickHandler } from 'src/common/utils/hooks/useLinkClickHandler'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { ButtonExternalLink } from 'src/common/components/externalSiteIcon'

const useStyles = makeStyles()(
  (
    defaultTheme,
    { backgroundColor, isFiftyFifty, reverse, shapeType, removeBottomPadding }
  ) => ({
    container: {
      padding: `${rem(40)} ${rem(24)} ${
        removeBottomPadding ? rem(0) : rem(40)
      }`,
      color: theme.palette.presidio.color.DARK_GRAY,
      backgroundColor: BACKGROUND_COLORS[backgroundColor],
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      flexWrap: 'nowrap',
      gap: rem(40),
      [theme.breakpoints.up('md')]: {
        flexDirection: reverse ? 'column-reverse' : 'column',
        padding: `${rem(60)} ${rem(40)} ${
          removeBottomPadding ? rem(0) : rem(60)
        }`,
      },
      [theme.breakpoints.up('lg')]: {
        flexDirection: reverse ? 'row-reverse' : 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        gap: rem(24),
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem(64)} ${rem(156)} ${
          removeBottomPadding ? rem(0) : rem(64)
        }`,
        gap: isFiftyFifty ? rem(120) : rem(24),
      },
      position: 'relative',
      overflow: 'clip',
    },
    backgroundImageBox: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      right: 0,
      userSelect: 'none',
      pointerEvents: 'none',
    },
    backgroundImage: {
      objectFit: 'contain',
      objectPosition: reverse ? 'left' : 'right',
    },
    textBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(24),
      [theme.breakpoints.up('md')]: {
        alignItems: 'start',
        gap: rem(24),
      },
      zIndex: 10,
    },
    heading: {
      color: theme.palette.primary.dark,
    },
    subHeading: {
      ...theme.typography.body.default,
      '& p': {
        margin: 0,
      },
      '& a': {
        ...theme.typography.body.inlineLink,
        color: theme.palette.primary.dark,
      },
    },
    listItemBox: {
      ...theme.typography.body.default,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: rem(16),
    },
    listItemImageBox: {
      width: rem(24),
      height: rem(24),
      minWidth: rem(24),
      minHeight: rem(24),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    listItemText: {
      '& p': {
        margin: 0,
      },
      '& a': {
        ...theme.typography.body.inlineLink,
        color: theme.palette.primary.dark,
      },
    },
    ctaButton: {
      width: '100%',
    },
    mediaBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(8),
      zIndex: 10,
    },
    imageMask: {
      WebkitMaskImage: `url(data:image/svg+xml;base64,${
        PHOTO_FRAME_BASE64_STRING[MASK_IMAGE_MAP[shapeType]]
      })`,
      WebkitMaskRepeat: 'no-repeat',
      WebkitMaskSize: 'cover',
      objectFit: 'cover',
    },
    imageInset: {
      objectFit: 'cover',
    },
    imageCaption: {
      ...theme.typography.smallBody.default,
    },
    videoContainer: {
      aspectRatio: '16/9',
    },
  })
)

// For inset & shape media frames only
export default function TextAndMedia(props) {
  const { data, isContact = false, order } = props

  if (!data || Object.keys(data).length <= 0) return null

  const { section_id, section_title, text_media } = data

  if (!text_media || Object.keys(text_media).length <= 0) return null

  const {
    ctaHeading: heading,
    tag_selection = 'h2',
    style_selection = 'h2',
    ctaSubheading: subHeading,
    informationlist = [],
    variant,
    layout_width: layout,
    image_framing_options: imageFrame,
    shape_types: shapeType,
    graphic_pattern: graphicPattern,
    button_style: buttonStyle,
    link,
    image,
    media,
    background_color: backgroundColor,
    versions,
    media_position: mediaPosition,
    video_url: videoURL,
    video_caption: videoCaption,
    video_framing_options: videoFrame,
    remove_bottom_padding: removeBottomPadding,
  } = text_media

  if (variant === 'text_list') {
    return <TextListAndMedia data={data} />
  }

  if (
    imageFrame === MEDIA_FRAME_TYPES.full_bleed ||
    imageFrame === MEDIA_FRAME_TYPES.semi_circle ||
    videoFrame === MEDIA_FRAME_TYPES.full_bleed
  ) {
    return <FullMedia data={data} isContact={isContact} /> // For full_bleed & semi_circle media frames
  }

  const hasPattern = !!graphicPattern && graphicPattern !== 'none'
  const hasImage = !!image?.url
  const hasCTA = !!link?.title || !!link?.url
  const hasVideo =
    media === MEDIA_TYPES.media_video && videoFrame === MEDIA_FRAME_TYPES.inset
  const isFiftyFifty = layout === LAYOUT_TYPES.fifty_fifty
  const isTextDominant = versions === VERSION_TYPES.text_dominant
  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))
  const reverse = mediaPosition === 'left'

  const backgroundPatternString = `${graphicPattern}${
    reverse ? '_left' : '_right'
  }`
  const video = { videoId: videoURL?.split('=')[1] }
  const { textPart, mediaPart } = getGridColsWidth(isFiftyFifty, isTextDominant)
  const { classes } = useStyles({
    backgroundColor,
    isFiftyFifty,
    reverse,
    shapeType,
    removeBottomPadding,
  })

  const linkClickHandler = useLinkClickHandler()

  return (
    <Grid
      className={`module ${classes.container}`}
      id={section_id}
      data-id="section"
      container
    >
      {lgUp && hasPattern && (
        <Box className={classes.backgroundImageBox}>
          <Image
            className={classes.backgroundImage}
            src={PATTERN_TYPES[backgroundPatternString]}
            alt="Background image"
            title="Background image"
            layout="fill"
          />
        </Box>
      )}
      <Grid className={classes.textBox} xs={12} lg={textPart} item>
        <Typography
          component={tag_selection}
          variant={style_selection}
          className={classes.heading}
        >
          {heading}
        </Typography>
        {subHeading && (
          <Typography
            className={classes.subHeading}
            variant="body"
            component="div"
          >
            {sanitize(subHeading)}
          </Typography>
        )}
        {informationlist &&
          informationlist?.map((listItem, index) => {
            if (!listItem?.information_icon && !listItem?.information_text)
              return null
            return (
              <Box
                key={index}
                className={classes.listItemBox}
                data-testid="list-item"
              >
                <Box className={classes.listItemImageBox}>
                  {listItem?.information_icon && (
                    <Image
                      src={listItem.information_icon.url}
                      width={listItem.information_icon.width}
                      height={listItem.information_icon.height}
                      alt={listItem.information_icon.alt || 'Icon'}
                      title={listItem?.information_icon?.title}
                    />
                  )}
                </Box>
                <Typography
                  className={classes.listItemText}
                  variant="body"
                  component="div"
                >
                  {sanitize(listItem.information_text)}
                </Typography>
              </Box>
            )
          })}
        {hasCTA && (
          <a
            href={link.url}
            onClick={(e) => {
              e.preventDefault()
              linkClickHandler(link)
            }}
            data-ga-location="5050_cta"
          >
            <Button
              className={classes.ctaButton}
              variant={buttonStyle}
              tabIndex={-1}
              endIcon={isUrlExternal(link?.url) ? <ButtonExternalLink /> : null}
            >
              {link?.title || 'Learn more'}
            </Button>
          </a>
        )}
      </Grid>
      <Grid className={classes.mediaBox} xs={12} lg={mediaPart} item>
        {hasVideo && videoURL && (
          <>
            <Box className={classes.videoContainer}>
              <Video video={video} />
            </Box>
            <Typography className={classes.imageCaption} variant="body">
              {videoCaption}
            </Typography>
          </>
        )}
        {!hasVideo && hasImage && (
          <>
            {imageFrame !== MEDIA_FRAME_TYPES.inset ? (
              <Image
                className={classes.imageMask}
                src={image?.url}
                alt={image?.alt || 'Media image'}
                title={image?.title}
                width={Math.max(image.width, image.height * 1.3867)} // for mask's aspect ratio
                height={Math.max(image.height, image.width / 1.3867)} // for mask's aspect ratio
              />
            ) : (
              <Image
                className={classes.imageInset}
                src={image?.url}
                alt={image?.alt || 'Media Image'}
                title={image?.title}
                width={image?.width}
                height={image?.height}
                priority={order < 3}
              />
            )}
            <Typography className={classes.imageCaption} variant="body">
              {image?.caption}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  )
}

TextAndMedia.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.string,
    section_id: PropTypes.string,
    text_media: PropTypes.shape({
      layout_width: PropTypes.string.isRequired,
      variant: PropTypes.oneOf(Object.keys(VARIANT_TYPES)),
      media_position: PropTypes.string.isRequired,
      button_style: PropTypes.string,
      background_color: PropTypes.string.isRequired,
      media: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
        description: PropTypes.string,
        caption: PropTypes.string,
        name: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
      video_url: PropTypes.string,
      video_caption: PropTypes.string,
      image_framing_options: PropTypes.string,
      shape_types: PropTypes.string,
      graphic_pattern: PropTypes.string,
      ctaHeading: PropTypes.string.isRequired,
      tag_selection: PropTypes.oneOf(['h2', 'h3']),
      style_selection: PropTypes.oneOf(['h2', 'h3']),
      ctaSubheading: PropTypes.string,
      informationlist: PropTypes.array,
      link: PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.string,
        url: PropTypes.string,
        name: PropTypes.string,
        title: PropTypes.string,
        target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      remove_bottom_padding: PropTypes.bool,
    }),
  }),
}
