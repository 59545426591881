import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import Image from 'next/image'
import { makeStyles } from 'tss-react/mui'
import Button from 'src/common/components/button/Button'
import { PHOTO_FRAME_BASE64_STRING } from 'src/common/constants'
import { rem } from 'src/common/utils/css'
import sanitize from 'src/common/utils/js/sanitize'
import ArrowRight from 'src/components/icons/ArrowRight'
import { MASK_IMAGE_MAP } from 'src/components/text-and-media/helpers'
import {
  BACKGROUND_COLORS,
  BACKGROUND_PATTERNS,
} from 'src/components/text-and-media/text-list-media/helpers'
import theme, { COLOR } from 'src/styles/theme'
import { useLinkClickHandler } from 'src/common/utils/hooks/useLinkClickHandler'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { TextButtonExternalLink } from 'src/common/components/externalSiteIcon'

const useStyles = makeStyles()(
  (
    defaultTheme,
    { backgroundColor, reverse, shapeMask, removeBottomPadding }
  ) => ({
    container: {
      padding: `${rem(40)} ${rem(24)} ${
        removeBottomPadding ? rem(0) : rem(40)
      }`,
      color: theme.palette.presidio.color.DARK_GRAY,
      backgroundColor: BACKGROUND_COLORS[backgroundColor],
      display: 'flex',
      flexDirection: reverse ? 'column-reverse' : 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      flexWrap: 'nowrap',
      gap: rem(24),
      position: 'relative',
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        padding: `${rem(64)} ${rem(40)} ${
          removeBottomPadding ? rem(0) : rem(64)
        }`,
        gap: rem(40),
      },
      [theme.breakpoints.up('lg')]: {
        padding: `${rem(64)} ${rem(reverse ? 0 : 40)} ${
          removeBottomPadding ? rem(0) : rem(64)
        } ${rem(reverse ? 40 : 0)}`,
        flexDirection: reverse ? 'row-reverse' : 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        gap: rem(24),
      },
      [theme.breakpoints.up('xl')]: {
        padding: `${rem(64)} ${rem(reverse ? 0 : 156)} ${
          removeBottomPadding ? rem(0) : rem(64)
        } ${rem(reverse ? 156 : 0)}`,
        gap: rem(56),
      },
    },
    backgroundImageBox: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '100%',
      userSelect: 'none',
      pointerEvents: 'none',
    },
    backgroundImage: {
      objectFit: 'contain',
      objectPosition: 'bottom',
    },
    textListBox: {
      padding: `${rem(40)} ${rem(16)}`,
      backgroundColor:
        backgroundColor !== 'background'
          ? COLOR.LIGHT_BACKGROUND
          : COLOR.BAKER_BEACH_WHITE,
      display: 'flex',
      flexDirection: 'column',
      gap: rem(24),
      zIndex: 10,
      [theme.breakpoints.up('md')]: {
        padding: rem(40),
      },
      [theme.breakpoints.up('lg')]: {
        padding: rem(40),
      },
      [theme.breakpoints.up('xl')]: {
        padding: reverse
          ? `${rem(64)} ${rem(156)} ${rem(64)} ${rem(64)}`
          : `${rem(64)} ${rem(64)} ${rem(64)} ${rem(156)}`,
      },
    },
    textListItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 0,
      gap: rem(8),
    },
    heading: {
      color:
        backgroundColor !== 'rooftop_red'
          ? theme.palette.primary.dark
          : theme.palette.primary.main,
    },
    subHeading: {
      ...theme.typography.body.default,
      '& p': {
        margin: 0,
      },
      '& a': {
        ...theme.typography.body.inlineLink,
        color: theme.palette.primary.dark,
      },
    },
    title: {
      color:
        backgroundColor !== 'rooftop_red'
          ? theme.palette.primary.dark
          : theme.palette.primary.main,
      fontWeight: 400,
    },
    description: {
      ...theme.typography.body.default,
      '& p': {
        margin: 0,
      },
      '& a': {
        ...theme.typography.body.inlineLink,
        color: theme.palette.primary.dark,
      },
    },
    mediaBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: 0,
      zIndex: 10,
    },
    imageMask: {
      WebkitMaskImage: `url(data:image/svg+xml;base64,${
        PHOTO_FRAME_BASE64_STRING[MASK_IMAGE_MAP[shapeMask]]
      })`,
      WebkitMaskRepeat: 'no-repeat',
      WebkitMaskSize: 'cover',
      objectFit: 'cover',
    },
    imageInset: {
      objectFit: 'cover',
    },
    imageCaption: {
      ...theme.typography.smallBody.default,
      color:
        backgroundColor === 'rooftop_red' || backgroundColor === 'cypress_green'
          ? COLOR.NEAR_WHITE
          : COLOR.DARK_GRAY,
      backgroundColor: BACKGROUND_COLORS[backgroundColor],
      paddingTop: rem(8),
      paddingBottom: rem(2),
    },
  })
)

export default function TextListAndMedia(props) {
  const { data } = props
  if (!data || Object.keys(data).length <= 0) return null

  const { section_id, section_title, text_media } = data
  if (!text_media || Object.keys(text_media).length <= 0) return null

  const {
    media_position: mediaPosition,
    background_color: backgroundColor,
    image_framing_options: imageFrame,
    graphic_pattern: backgroundPattern,
    image,
    ctaHeading: heading,
    tag_selection = 'h2',
    style_selection = 'h2',
    ctaSubheading: subheading,
    informationlist: textList,
    remove_bottom_padding: removeBottomPadding,
  } = text_media

  let backgroundPatternColor = ''
  if (
    backgroundColor === 'background' ||
    backgroundColor === 'baker_beach' ||
    backgroundColor === 'crissy_field_40'
  ) {
    backgroundPatternColor = '_crissy_field'
  } else if (backgroundColor === 'cypress_green') {
    backgroundPatternColor = '_cypress_green'
  } else if (backgroundColor === 'rooftop_red') {
    backgroundPatternColor = '_white'
  } else if (backgroundColor === 'crissy_field_60') {
    backgroundPatternColor = '_white'
  }

  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))
  const pattern =
    BACKGROUND_PATTERNS[`${backgroundPattern}${backgroundPatternColor}`]
  const reverse = mediaPosition === 'left'
  const shapeMask = imageFrame === 'shape' ? 'winfield_short' : ''

  const { classes } = useStyles({
    backgroundColor,
    reverse,
    shapeMask,
    removeBottomPadding,
  })

  const linkClickHandler = useLinkClickHandler()

  const gaTag = '5050v2'

  return (
    <Grid
      className={`module ${classes.container}`}
      id={section_id}
      data-id="section"
      container
    >
      {lgUp && backgroundPattern && (
        <Box className={classes.backgroundImageBox}>
          <Image
            className={classes.backgroundImage}
            src={pattern}
            alt="background image"
            title="Background image"
            layout="fill"
          />
        </Box>
      )}
      <Grid className={classes.textListBox} xs={12} lg={6.25} xl={6.6} item>
        {(heading || subheading) && (
          <Box className={classes.textListItem}>
            {heading && (
              <Typography
                component={tag_selection}
                variant={style_selection}
                className={classes.heading}
              >
                {heading}
              </Typography>
            )}
            {subheading && (
              <Typography
                className={classes.subHeading}
                variant="body"
                component="div"
              >
                {sanitize(subheading, gaTag)}
              </Typography>
            )}
          </Box>
        )}
        {textList &&
          Array.isArray(textList) &&
          textList?.map((listItem, index) => (
            <Box
              key={index}
              className={classes.textListItem}
              data-testid="text-list"
            >
              <Typography className={classes.title} variant="h3">
                {listItem.text_list_title}
              </Typography>
              {listItem.text_list_description && (
                <Typography
                  className={classes.description}
                  variant="body"
                  component="div"
                >
                  {sanitize(listItem.text_list_description, gaTag)}
                </Typography>
              )}
              {listItem?.text_list_cta && (
                <a
                  href={listItem.text_list_cta.url}
                  onClick={(e) => {
                    e.preventDefault()
                    linkClickHandler(listItem.text_list_cta)
                  }}
                  data-ga-location={gaTag}
                >
                  <Button
                    className={classes.ctaButton}
                    variant="text"
                    endIcon={
                      isUrlExternal(listItem.text_list_cta?.url) ? (
                        <TextButtonExternalLink />
                      ) : (
                        <ArrowRight />
                      )
                    }
                    tabIndex={-1}
                  >
                    {listItem.text_list_cta?.title || 'Learn more'}
                  </Button>
                </a>
              )}
            </Box>
          ))}
      </Grid>
      <Grid className={classes.mediaBox} xs={12} lg={5.75} xl={5.4} item>
        {image?.url && (
          <>
            {imageFrame === 'shape' && (
              <Image
                className={classes.imageMask}
                src={image.url}
                alt={image.alt || 'Media image'}
                title={image?.title}
                width={Math.max(image.width, image.height * 1.3867)} // mask's aspect ratio
                height={Math.max(image.height, image.width / 1.3867)} // mask's aspect ratio
              />
            )}
            {imageFrame === 'rectangle' && (
              <Image
                className={classes.imageInset}
                src={image.url}
                alt={image.alt || 'Media image'}
                title={image?.title}
                width={Math.max(image.width, image.height * 1.38)} // design's aspect ratio
                height={Math.max(image.height, image.width / 1.38)} // design's aspect ratio
              />
            )}
            {image?.caption && (
              <Typography className={classes.imageCaption} variant="body">
                {image.caption}
              </Typography>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}
