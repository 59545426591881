import beachStrawberryLeftPattern from 'public/assets/patterns/beach-strawberry/CrissyField_CropLeft.png'
import beachStrawberryRightPattern from 'public/assets/patterns/beach-strawberry/CrissyField_CropRight.png'
import forestLeftPattern from 'public/assets/patterns/forest/CrissyField_CropLeft.png'
import forestRightPattern from 'public/assets/patterns/forest/CrissyField_CropRight.png'
import landSeaLeftPattern from 'public/assets/patterns/land-sea/CrissyField_CropLeft.png'
import landSeaRightPattern from 'public/assets/patterns/land-sea/CrissyField_CropRight.png'
import manzanitaLeftPattern from 'public/assets/patterns/manzanita/CrissyField_CropLeft.png'
import manzanitaRightPattern from 'public/assets/patterns/manzanita/CrissyField_CropRight.png'
import { COLOR, SECONDARY_COLOR } from 'src/styles/theme'

export function getGridColsWidth(isFiftyFifty, isTextDominant) {
  let textPart = 0
  let mediaPart = 0

  if (isFiftyFifty) {
    textPart = 6
    mediaPart = 6
  } else if (isTextDominant) {
    textPart = 7
    mediaPart = 5
  } else {
    textPart = 4
    mediaPart = 8
  }
  return { textPart, mediaPart }
}

export const BACKGROUND_COLORS = {
  crissy_field: SECONDARY_COLOR.LIGHT[40],
  baker_beach: COLOR.BAKER_BEACH_WHITE,
  background: COLOR.LIGHT_BACKGROUND,
}

export const MEDIA_FRAME_TYPES = {
  full_bleed: 'full_bleed',
  semi_circle: 'semi_circle',
  inset: 'inset',
  shape: 'shape',
}

export const LAYOUT_TYPES = {
  fifty_fifty: 'fifty_fifty',
  thirty_seventy: 'thirty_seventy',
}

export const PATTERN_TYPES = {
  beach_strawberry_left: beachStrawberryLeftPattern,
  beach_strawberry_right: beachStrawberryRightPattern,
  forest_left: forestLeftPattern,
  forest_right: forestRightPattern,
  land_and_sea_left: landSeaLeftPattern,
  land_and_sea_right: landSeaRightPattern,
  manzanita_left: manzanitaLeftPattern,
  manzanita_right: manzanitaRightPattern,
}

export const VERSION_TYPES = {
  media_dominant: 'media_dominant',
  text_dominant: 'text_dominant',
}

export const MEDIA_TYPES = {
  media_image: 'media_image',
  media_video: 'media_video',
}

export const MASK_IMAGE_MAP = {
  dome_short: 'DOM_SHORT',
  winfield_half_short: 'WINFIELD_HALF_SHORT',
  winfield_short: 'WINFIELD_SHORT',
}

export const VARIANT_TYPES = {
  basic: 'basic',
  text_list: 'text_list',
}
