/* eslint-disable no-nested-ternary */
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import Image from 'next/image'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import Button from 'src/common/components/button/Button'
import Video from 'src/common/components/video/Video'
import { PHOTO_FRAME_BASE64_STRING } from 'src/common/constants'
import { rem } from 'src/common/utils/css'
import sanitize from 'src/common/utils/js/sanitize'
import {
  BACKGROUND_COLORS,
  LAYOUT_TYPES,
  MEDIA_FRAME_TYPES,
  MEDIA_TYPES,
  VERSION_TYPES,
  getGridColsWidth,
} from 'src/components/text-and-media/helpers'
import theme, { SECONDARY_COLOR } from 'src/styles/theme'
import { useLinkClickHandler } from 'src/common/utils/hooks/useLinkClickHandler'
import isUrlExternal from 'src/common/utils/js/isUrlExternal'
import { ButtonExternalLink } from 'src/common/components/externalSiteIcon'

const useStyles = makeStyles()(
  (
    defaultTheme,
    { backgroundColor, isContact, isFiftyFifty, reverse, removeBottomPadding }
  ) => ({
    container: {
      // paddingBottom: removeBottomPadding ? 0 : reverse ? rem(40) : 0, // Commented post regression checks
      // paddingTop: reverse ? 0 : rem(40),
      paddingBottom: removeBottomPadding ? 0 : rem(40),
      color: theme.palette.presidio.color.DARK_GRAY,
      backgroundColor: isContact
        ? SECONDARY_COLOR.LIGHT[60]
        : BACKGROUND_COLORS[backgroundColor],
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      gap: rem(40),
      position: 'relative',
      overflow: 'clip',
      [theme.breakpoints.up('md')]: {
        flexDirection: reverse ? 'column-reverse' : 'column',
        paddingTop: reverse ? 0 : rem(64),
        paddingBottom: removeBottomPadding ? 0 : reverse ? rem(64) : 0,
      },
      [theme.breakpoints.up('lg')]: {
        minHeight: rem(400),
        padding: 0,
        flexDirection: reverse ? 'row-reverse' : 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        gap: rem(24),
      },
      [theme.breakpoints.up('xl')]: {
        minHeight: rem(480),
        gap: isFiftyFifty ? rem(120) : rem(24),
      },
    },
    textBox: {
      padding: `0rem ${rem(24)}`,
      display: 'flex',
      flexDirection: 'column',
      gap: rem(24),
      [theme.breakpoints.up('md')]: {
        padding: `0rem ${rem(40)}`,
        alignItems: 'start',
        gap: rem(24),
      },
      [theme.breakpoints.up('lg')]: {
        padding: reverse
          ? `${rem(60)} ${rem(40)} ${rem(60)} 0`
          : `${rem(60)} 0 ${rem(60)} ${rem(40)}`,
      },
      [theme.breakpoints.up('xl')]: {
        padding: reverse
          ? `${rem(64)} ${rem(156)} ${rem(64)} 0`
          : `${rem(64)} 0 ${rem(64)} ${rem(156)}`,
      },
    },
    heading: {
      color: theme.palette.primary.dark,
      fontWeight: 500,
      [theme.breakpoints.up('md')]: {
        fontWeight: 500,
      },
    },
    subHeading: {
      ...theme.typography.body.default,
      '& p': {
        margin: 0,
      },
      '& a': {
        ...theme.typography.body.inlineLink,
        color: theme.palette.primary.dark,
      },
    },
    listItemBox: {
      ...theme.typography.body.default,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: rem(16),
    },
    listItemImageBox: {
      width: rem(24),
      height: rem(24),
      minWidth: rem(24),
      minHeight: rem(24),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    listItemText: {
      '& p': {
        margin: 0,
      },
      '& a': {
        ...theme.typography.body.inlineLink,
        color: theme.palette.primary.dark,
      },
    },
    ctaButton: {
      width: '100%',
      fontWeight: 500,
    },
    mediaBox: {
      display: 'flex',
      alignSelf: 'stretch',
      '& span': {
        width: '100% !important',
      },
    },
    imageMask: {
      WebkitMaskImage: `url(data:image/svg+xml;base64,${
        reverse
          ? PHOTO_FRAME_BASE64_STRING.SEMI_CIRCLE_IMAGE_LEFT
          : PHOTO_FRAME_BASE64_STRING.SEMI_CIRCLE_IMAGE_RIGHT
      })`,
      WebkitMaskRepeat: 'no-repeat',
      WebkitMaskSize: 'cover',
      WebkitMaskPosition: reverse ? 'right' : 'left',
      objectFit: 'cover',
    },
    imageFullBleed: {
      objectFit: 'cover',
    },
    videoContainer: {
      width: '100vw',
      aspectRatio: '16/9',
      [theme.breakpoints.up('lg')]: {
        width: '100%',
      },
    },
    noTopPadding: {
      paddingTop: '0 !important',
    },
    noBottomPadding: {
      paddingBottom: '0 !important',
    },
  })
)

// For full_bleed & semi_circle media frame only
export default function FullMedia(props) {
  const { data, isContact = false } = props

  if (!data || Object.keys(data).length <= 0) return null

  const { section_id, section_title, text_media } = data

  if (!text_media || Object.keys(text_media).length <= 0) return null

  const {
    ctaHeading: heading,
    tag_selection = 'h2',
    style_selection = 'h2',
    ctaSubheading: subHeading,
    informationlist = [],
    layout_width: layout,
    image_framing_options: imageFrame,
    button_style: buttonStyle,
    link,
    image,
    media,
    background_color: backgroundColor,
    versions,
    media_position: mediaPosition,
    video_url: videoURL,
    video_framing_options: videoFrame,
    remove_bottom_padding: removeBottomPadding,
  } = text_media

  const hasCTA = !!link?.title || !!link?.url
  const hasImage = !!image?.url
  const hasVideo =
    media === MEDIA_TYPES.media_video &&
    videoFrame === MEDIA_FRAME_TYPES.full_bleed
  const isFiftyFifty = layout === LAYOUT_TYPES.fifty_fifty
  const isTextDominant = versions === VERSION_TYPES.text_dominant
  const lgUp = useMediaQuery(() => theme.breakpoints.up('lg'))
  const reverse = mediaPosition === 'left'

  const video = { videoId: videoURL?.split('=')[1] }
  const { textPart, mediaPart } = getGridColsWidth(isFiftyFifty, isTextDominant)

  const { classes } = useStyles({
    backgroundColor,
    isContact,
    isFiftyFifty,
    reverse,
    removeBottomPadding,
  })
  let noBottomPaddingFlag = false
  if (mediaPosition === 'right') {
    noBottomPaddingFlag = true
  } else if (mediaPosition === 'left' && lgUp) {
    noBottomPaddingFlag = true
  }

  const linkClickHandler = useLinkClickHandler()

  return (
    <Grid
      className={`module full-media ${lgUp ? `${classes.noTopPadding}` : ''} ${
        lgUp && noBottomPaddingFlag
          ? `no-bottom-padding ${classes.noBottomPadding}`
          : ''
      } ${classes.container}`}
      id={section_id}
      data-id="section"
      container
    >
      <Grid className={classes.textBox} xs={12} lg={textPart} item>
        <Typography
          component={tag_selection}
          variant={style_selection}
          className={classes.heading}
        >
          {heading}
        </Typography>
        {subHeading && (
          <Typography
            className={classes.subHeading}
            variant="body"
            component="div"
          >
            {sanitize(subHeading)}
          </Typography>
        )}
        {informationlist &&
          informationlist?.map((listItem, index) => {
            if (!listItem?.information_icon && !listItem?.information_text)
              return null
            return (
              <Box
                key={index}
                className={classes.listItemBox}
                data-testid="list-item"
              >
                <Box className={classes.listItemImageBox}>
                  {listItem?.information_icon && (
                    <Image
                      src={listItem?.information_icon?.url}
                      width={listItem?.information_icon?.width}
                      height={listItem?.information_icon?.height}
                      alt={listItem?.information_icon?.alt || 'Icon'}
                      title={listItem?.information_icon?.title}
                    />
                  )}
                </Box>
                <Typography
                  className={classes.listItemText}
                  variant="body"
                  component="div"
                >
                  {sanitize(listItem.information_text)}
                </Typography>
              </Box>
            )
          })}
        {hasCTA && (
          <a
            href={link.url}
            onClick={(e) => {
              e.preventDefault()
              linkClickHandler(link)
            }}
            data-ga-location="5050_cta"
          >
            <Button
              className={classes.ctaButton}
              variant={buttonStyle}
              tabIndex={-1}
              endIcon={isUrlExternal(link?.url) ? <ButtonExternalLink /> : null}
            >
              {link?.title || 'Learn more'}
            </Button>
          </a>
        )}
      </Grid>
      <Grid className={classes.mediaBox} xs={12} lg={mediaPart} item>
        {hasVideo && videoURL && (
          <Box className={classes.videoContainer}>
            <Video video={video} />
          </Box>
        )}
        {!hasVideo &&
          hasImage &&
          (lgUp && imageFrame === MEDIA_FRAME_TYPES.semi_circle ? (
            <Image
              className={classes.imageMask}
              src={image?.url}
              alt={image?.alt || 'Media image'}
              title={image?.title}
              width={image?.width}
              height={image?.height}
            />
          ) : (
            <Image
              className={classes.imageFullBleed}
              src={image?.url}
              alt={image?.alt || 'Media image'}
              title={image?.title}
              width={image?.width}
              height={image?.height}
            />
          ))}
      </Grid>
    </Grid>
  )
}

FullMedia.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.string,
    section_id: PropTypes.string,
    text_media: PropTypes.shape({
      layout_width: PropTypes.string.isRequired,
      media_position: PropTypes.string.isRequired,
      button_style: PropTypes.string.isRequired,
      background_color: PropTypes.string.isRequired,
      media: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
        description: PropTypes.string,
        caption: PropTypes.string,
        name: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
      video_url: PropTypes.string,
      image_framing_options: PropTypes.string,
      ctaHeading: PropTypes.string.isRequired,
      tag_selection: PropTypes.oneOf(['h2', 'h3']),
      style_selection: PropTypes.oneOf(['h2', 'h3']),
      ctaSubheading: PropTypes.string,
      informationlist: PropTypes.array,
      link: PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.string,
        url: PropTypes.string,
        name: PropTypes.string,
        title: PropTypes.string,
        target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      remove_bottom_padding: PropTypes.bool,
    }),
  }),
}
