import BeachStrawberryCrissyField from 'public/assets/patterns/beach-strawberry/CrissyField_FullWidth.png'
import BeachStrawberryCypressGreen from 'public/assets/patterns/beach-strawberry/CypressGreen_FullWidth.png'
import BeachStrawberryWhite from 'public/assets/patterns/beach-strawberry/Patterns_BeachStrawberry_TransparentWhite_FullWidth.png'
import ManzanitaCrissyField from 'public/assets/patterns/manzanita/CrissyField_FullWidth.png'
import ManzanitaCypressGreen from 'public/assets/patterns/manzanita/CypressGreen_FullWidth.png'
import ManzanitaWhite from 'public/assets/patterns/manzanita/Patterns_Manzanita_TransparentWhite_FullWidth 2.png'
import PelicanFootPrintsCrissyField from 'public/assets/patterns/pelican-footprints/CrissyField_FullWidth.png'
import PelicanFootPrintsCypressGreen from 'public/assets/patterns/pelican-footprints/CypressGreen_FullWidth.png'
import PelicanFootPrintsWhite from 'public/assets/patterns/pelican-footprints/Patterns_PelicanFootprints_TransparentWhite_FullWidth 1.png'
import theme, { COLOR, SECONDARY_COLOR } from 'src/styles/theme'

export const BACKGROUND_COLORS = {
  background: COLOR.LIGHT_BACKGROUND,
  baker_beach: COLOR.BAKER_BEACH_WHITE,
  crissy_field_60: SECONDARY_COLOR.LIGHT[60],
  crissy_field_40: SECONDARY_COLOR.LIGHT[40],
  cypress_green: theme.palette.primary.dark,
  rooftop_red: theme.palette.primary.main,
}

export const BACKGROUND_PATTERNS = {
  beach_strawberry_crissy_field: BeachStrawberryCrissyField,
  beach_strawberry_cypress_green: BeachStrawberryCypressGreen,
  beach_strawberry_white: BeachStrawberryWhite,
  manzanita_crissy_field: ManzanitaCrissyField,
  manzanita_cypress_green: ManzanitaCypressGreen,
  manzanita_white: ManzanitaWhite,
  pelican_footprints_crissy_field: PelicanFootPrintsCrissyField,
  pelican_footprints_cypress_green: PelicanFootPrintsCypressGreen,
  pelican_footprints_white: PelicanFootPrintsWhite,
}
